.modalWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  z-index: 9999;
}

.open {
  height: 100%;
  display: block;
}

.close {
  height: 0;
  width: 0;
  display: none;
}

.modalBorder {
  display: flex;
  align-items: center;
}

.modalBody {
  width: 400px;
  min-height: 400px;
  background-color: #fff;
  border-radius: 5px;
  padding: 0 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.modalBody:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(37, 37, 37, 0.5);
  z-index: 2;
}

.modalClose {
  position: relative;
  font-size: 30px;
  cursor: pointer;
  margin-left: 99%;
  z-index: 999;
}
