.button {
  position: relative;
  z-index: 555;
  margin-left: 20px;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 7px 40px;
  background-color: rgb(245, 90, 150);
  cursor: pointer;
}

.button:active {
  background-color: rgb(87, 87, 87);
}

.showMoreButton {
  margin: 0 auto;
  display: block;
}
