.preloader {
  position: relative;
  left: 0;
  width: 100%;
  min-height: calc(100vh - 90px);
  visibility: visible;
  background: #f5f5dc url(img/preloader.svg) no-repeat center center;
  transition: is all;
  opacity: 1;
  visibility: visible;
}
