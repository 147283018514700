.App {
  margin: 0;
}

.netflixroulette {
  color: rgb(245, 90, 150);
  margin-top: 0;
  z-index: 555;
  position: relative;
  display: block;
  font-weight: 600;
  font-size: 25px;
}

.headerList {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.headerListItem {
  margin: auto 20px auto 0;
  font-weight: bold;
  font-size: 18px;
}

.link {
  text-decoration: none;
  color: #fff;
}
