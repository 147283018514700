.moviePage {
  min-height: calc(100vh - 90px);
  padding: 0 20%;
}

.movieTitle {
  text-align: center;
  margin-bottom: 50px;
}

.posterImg {
  display: block;
  min-width: 30%;
}

.movieItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.movieInfo {
  margin: 0 30px;
}

.movieGenre {
  margin-right: 10px;
}

.movieVideo {
  margin-bottom: 50px;
}
