.movieItem {
  display: flex;
  flex-direction: column;
  width: 15%;
  margin: 0px 20px 60px;
  background-color: rgb(245, 214, 226);
  border-bottom: 1px solid rgb(245, 90, 150);
}

.posterImg {
  display: block;
  width: 100%;
}

.movieMainInfo {
  display: flex;
  justify-content: space-between;
  margin: 0 5px 10px;
  padding: 0;
}

.dateRelease {
  display: block;
  margin: auto 1px;
  border: 1px solid gray;
  padding: 3px 5px;
  border-radius: 3px;
}

.movieTitle {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 0;
  display: block;
  color: rgb(0, 0, 0);
}

.movieGenre,
.dateRelease {
  font-size: 12px;
}

.dateRelease {
  margin-left: 15px;
}
