.moviesContainer {
  min-height: calc(100vh - 140px);
  margin-bottom: 50px;
}

.moviesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10%;
}

.moviesTopBar {
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
}

.chooseMovieFilter {
  display: flex;
  justify-content: space-between;
}

p {
  margin-top: 0;
}

.netflixroulette {
  color: rgb(245, 90, 150);
  margin-top: 0;
  z-index: 555;
  position: relative;
  display: block;
  font-weight: 600;
  font-size: 25px;
}

.subTitle {
  z-index: 3;
  position: relative;
  margin-top: 50px;
}
