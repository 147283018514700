.searchForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.searchFormInput {
  padding: 15px;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  border: none;
  border-bottom: 2px solid rgb(245, 90, 150);
  color: rgb(255, 255, 255);
  z-index: 3;
  outline: none;
  margin-bottom: 20px;
}

.search {
  display: flex;
  justify-content: space-between;
}

.chooseSearchBy {
  display: flex;
  justify-content: space-between;
}

.searchBySpan {
  z-index: 3;
  position: relative;
}
